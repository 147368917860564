import React, {CSSProperties, useState} from "react";
import {ButtonComponent, GoBackLinkComponent, TranslationComponent} from "@vivli/shared/components";
import {Color, Size, Styles} from "@vivli/shared/theme";
import {useEnquiryContext} from "@vivli/features/enquiry/infastructure/context";
import {useFormContext} from "react-hook-form";
import {IEnquiry} from "@vivli/features/enquiry/infastructure/interface";
import {defaultDataInfoRequest} from "@vivli/features/enquiry/infastructure/wrappers";
import {
    DirStatusEnum,
    DirStudyNotAvailableResponseEnum,
    EnquiryStatusEnum
} from "@vivli/features/enquiry/infastructure/enum";
import {useActiveUser} from "@vivli/core/infrastructure/context";
import {useModalService} from "@vivli/shared/infrastructure/context";
import {DataRequestStatusEnum} from "@vivli/features/data-requests/infrastructure/enum";
import {IDropdownMenuItem} from "@vivli/shared/infrastructure/interface";
import {AssetsConstant, DTICommonConst} from "@vivli/shared/infrastructure/constants";
import {useSharedDataContext} from "@vivli/features/data-requests/infrastructure/context";
import {AdvancedDropdownMenuComponent} from "@vivli/features/search/components";

const flexStyle: CSSProperties = {
    display: "flex",
    justifyContent: "space-between",
    alignItems: 'center',
}
const marginLeft20: CSSProperties = {
    marginLeft: '20px',
}
const backLinkStyle: CSSProperties = {
    display: 'inline-block',
    marginRight: '20px',
}
const headerStyle: CSSProperties = {
    backgroundColor: '#FFF',
    boxShadow: 'rgba(0, 0, 0, 0.1) 0px 1px 3px, rgba(0, 0, 0, 0.1) 0px 1px 3px',
    padding: '10px 20px',
    height: '70px',
    minHeight: '70px',
    position: 'sticky',
    top: '0',
}
const dropDownBtnStyle: CSSProperties = {
    backgroundImage: `url(${AssetsConstant.DOWN_CHEVRON_WHITE})`,
    paddingRight: Size.PADDING,
    fontWeight: Size.FontWeight.SemiBold,
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'right 20px center',
    backgroundSize: '20px',
    marginLeft: '15px',
    marginRight: '0',
    width: '200px',
    minWidth: '280px',
}
export const EnquiryHeaderComponent = () => {

    const {
        handleFormSave,
        updateStatus,
        enquiry,
        formatStatus,
        getAddDirStudy,
        isLoading,
        displaySuccessToastAndScrollToBottom
    } = useEnquiryContext();
    const user = useActiveUser();
    const modalService = useModalService();
    const {myDataRequests} = useSharedDataContext().myDataRequests;
    const formApi = useFormContext<IEnquiry>();
    const [isRequestStudyMenuOpen, setIsRequestStudyMenuOpen] = useState(false);
    const toastAddNewSuccessResponse = 'New Study has been Successfully Added.';
    const isWithdrawnOrArchived = enquiry?.status === EnquiryStatusEnum.Withdrawn || enquiry?.status === EnquiryStatusEnum.Archived;
    const orgIds = enquiry?.dataInfoRequests?.map(x => x.orgId);
    const showSave = user?.isVivliAdmin ? true : (user?.userId === enquiry?.requesterId && !isWithdrawnOrArchived || enquiry?.status === EnquiryStatusEnum.Review ||
        isWithdrawnOrArchived && user.orgMemberships.some(x => orgIds.some(xx=> xx == x.orgId) && x.isOrgAdmin));
    const isAdminOrRequestor = user?.id == enquiry?.requesterId || user?.isVivliAdmin;
    const showSaveNotify = enquiry?.status == EnquiryStatusEnum.Review ||
                           ((enquiry?.status == EnquiryStatusEnum.Draft ||
                            enquiry?.status == EnquiryStatusEnum.EnquiryValidation)
                            && isAdminOrRequestor);


    const handleSelectMenuOutsideClick = () => {
        setIsRequestStudyMenuOpen(false);
    };
    const handleSaveEnquiry = (notify: boolean) => {
        const reasonAvailable = formApi.getValues().dataInfoRequests.filter(
            (dir) => dir.status === DirStatusEnum.NotAvailable && dir.notAvailableReason === DirStudyNotAvailableResponseEnum.None
        );
        if (reasonAvailable.length > 0) {
            modalService.error(`Please select a Reason when the selected Response is "Not Available"`);
        } else {
            handleFormSave(formApi, false, notify);
        }
    }

    const handleAddDirStudy = () => {
        formApi.setValue('dataInfoRequests', [...formApi.getValues().dataInfoRequests, {...defaultDataInfoRequest}]);
        displaySuccessToastAndScrollToBottom(toastAddNewSuccessResponse);
    }

    const addToCart = (selectedItem?: IDropdownMenuItem) => {
        const isNewRequest = selectedItem.value === 'addnewrequest';
        const receivedTitle = isNewRequest ? 'New Research Data Request' : selectedItem.title;
        const dirId = 0;
        getAddDirStudy(enquiry.id, dirId, receivedTitle, isNewRequest);
        setIsRequestStudyMenuOpen(false);

    };

    const displayAddDirBtn = enquiry?.dataInfoRequests?.find(dir => dir.status === DirStatusEnum.AvailableUnlisted
        || dir.status === DirStatusEnum.AvailableListed);

    const dropDownStudyItems = (myDataRequests || [])
        .filter((dr) => dr.userId === user.userId && dr.status === DataRequestStatusEnum.Draft)
        .map(
            (dataRequest) =>
                ({
                    title: dataRequest.requestTitle || dataRequest.id,
                    value: dataRequest.id,
                    dataRequest,
                } as IDropdownMenuItem)
        )
        .concat({
            title: '+ Add New Request',
            value: 'addnewrequest',
        } as IDropdownMenuItem)


    return (
        <div style={{...flexStyle, ...headerStyle}}>
            <div>
                <span style={backLinkStyle}><GoBackLinkComponent/></span>
                <span>Enquiry Id: {enquiry?.id}</span>
                <span style={marginLeft20}>Status: {formatStatus(enquiry?.status)}</span>
                <span style={marginLeft20}>Date Submitted: {enquiry?.submittedDate?.substring(0, 10)}</span>
            </div>
            {enquiry &&
                <div style={flexStyle}>
                    {user.isVivliAdmin && (enquiry.status != EnquiryStatusEnum.Withdrawn) &&
                        <ButtonComponent title="Withdraw"
                                         style={{
                                             marginLeft: Size.PADDING,
                                         }}
                                         onClick={() => updateStatus(EnquiryStatusEnum.Withdrawn, formApi)}>
                            Withdraw
                        </ButtonComponent>
                    }
                    {user.isVivliAdmin && (enquiry.status != EnquiryStatusEnum.Archived) &&
                        <ButtonComponent title="Archive"
                                         style={{
                                             marginLeft: Size.PADDING,
                                         }}
                                         onClick={() => updateStatus(EnquiryStatusEnum.Archived, formApi)}>
                            Archive
                        </ButtonComponent>
                    }
                    {isAdminOrRequestor && enquiry.status == EnquiryStatusEnum.Draft &&
                        <ButtonComponent title="Add Study"
                                         style={{
                                             marginLeft: Size.PADDING,
                                         }}
                                         onClick={handleAddDirStudy}
                        >
                            Add Study
                        </ButtonComponent>
                    }
                    {showSave &&
                        <ButtonComponent title="Save"
                                         style={{
                                             marginLeft: Size.PADDING,
                                         }}
                                         onClick={() => handleSaveEnquiry(false)}>
                            Save
                        </ButtonComponent>
                    }
                    {showSaveNotify &&
                        <ButtonComponent title="Save & Notify all parties about the new comments."
                                         style={{
                                             marginLeft: Size.PADDING,
                                         }}
                                         onClick={() => handleSaveEnquiry(true)}>
                            Save & Notify
                        </ButtonComponent>
                    }
                    {isAdminOrRequestor && enquiry.status == EnquiryStatusEnum.Draft &&
                        <ButtonComponent title="Submit"
                                         style={{
                                             marginLeft: Size.PADDING,
                                         }}
                                         disabled={!formApi.formState.isValid}
                                         onClick={() => updateStatus(EnquiryStatusEnum.EnquiryValidation, formApi)}>
                            Submit
                        </ButtonComponent>
                    }
                    {user.isVivliAdmin && enquiry.status == EnquiryStatusEnum.EnquiryValidation &&
                        <ButtonComponent title="Accept for Review"
                                         style={{
                                             marginLeft: Size.PADDING,
                                         }}
                                         onClick={() => updateStatus(EnquiryStatusEnum.Review, formApi)}>
                            Accept for Review
                        </ButtonComponent>
                    }
                    {user.isVivliAdmin && (enquiry.status != EnquiryStatusEnum.Draft) &&
                        <ButtonComponent title="Return to Draft"
                                         style={{
                                             marginLeft: Size.PADDING,
                                         }}
                                         onClick={() => updateStatus(EnquiryStatusEnum.Draft, formApi)}>
                            Return to Draft
                        </ButtonComponent>
                    }

                    {isAdminOrRequestor && (enquiry.status == EnquiryStatusEnum.Review) && displayAddDirBtn &&
                        <AdvancedDropdownMenuComponent
                            items={dropDownStudyItems}
                            onClickOutside={() => handleSelectMenuOutsideClick()}
                            onItemClick={(item) => addToCart(item)}
                        >
                            <ButtonComponent
                                isLoading={isLoading}
                                style={
                                    {
                                        ...Styles.Button.BUTTON_VIVLI_BLUE,
                                        backgroundColor: isRequestStudyMenuOpen ? Color.VIVLI_DARK_BLUE : Color.VIVLI_LIGHT_BLUE,
                                        ...dropDownBtnStyle,
                                        ':active': 'inherit',
                                    } as CSSProperties
                                }
                                className="request_study_menu"
                                data-test-id={DTICommonConst.RequestStudyMenu}
                            >
                                Request{' '} Available{' '}
                                <TranslationComponent
                                    style={{
                                        textTransform: 'capitalize',
                                    }}
                                >
                                    studies
                                </TranslationComponent>
                            </ButtonComponent>
                        </AdvancedDropdownMenuComponent>
                    }

                </div>
            }
        </div>
    );
}
