﻿import {CheckboxComponent, DatePickerComponent, TextAreaComponent} from "@vivli/shared/components";
import {CSSProperties} from "react";
import {useDataRequestContext} from "@vivli/features/data-requests/infrastructure/context";
import {useActiveUser} from "@vivli/core/infrastructure/context";

const executionComponentStyle: CSSProperties = {
    display: "flex",
    flexDirection: "column",
    gap: "20px"
}


const executionTabStyle: CSSProperties = {
    display: 'flex',
    alignItems: "center",
    width: "25%",
    gap: "10px"
}
const dateComponentStyle: CSSProperties = {
    display: "flex",
    flexDirection: "column",
    gap: "10px"
}

const readonlyStyle: CSSProperties = {
    cursor: 'default',
    pointerEvents: 'none',
    backgroundColor: 'rgb(210, 213, 217)'
}

const institutionContainerStyle: CSSProperties = {
    width: "100%",
    height: "200px",
    backgroundColor: 'rgb(255, 246, 217)',
    display: "flex",
    flexDirection: "column",
    gap: "40px",
    padding: "30px"
}

const institutionDivStyle: CSSProperties = {
    display: "flex",
    flexDirection: "column",
    gap: "10px"

}

export const DuaExecutionComponent = ({isOrgAdmin}) => {


    const {duaDetails, setDuaDetails, setStatusUpdateState, statusUpdateState} = useDataRequestContext();
    const inputStyle = isOrgAdmin ? readonlyStyle : null;
    const { isVivliAdmin } = useActiveUser();


    const handleDuaDateChange = (field, value) => {
        setDuaDetails({...duaDetails, [field]: new Date(value)})
    }
    const handleDuaUpdate = (field, value) => {
        setDuaDetails({...duaDetails, [field]: value})
    }

    const handleDuaStatusChange = (newValue) => {
        setStatusUpdateState({...statusUpdateState, duaStatus: newValue});
    };


    return (
        <div style={executionComponentStyle}>
            <div style={{display: "flex", alignItems: "center"}}>
                <CheckboxComponent onChange={(event) => {
                    handleDuaUpdate('inactive', !event.target.checked)
                }}
                                   disabled={isOrgAdmin}
                                   checked={!duaDetails?.inactive}/>
                <div>Active</div>
            </div>

            <div style={dateComponentStyle}>
                <div style={executionTabStyle}>
                    <div style={{width: "40%"}}>Original Execution:</div>
                    <DatePickerComponent style={{width: "40%"}}
                                         readonly={isOrgAdmin}
                                         onChange={(event) => {
                                             handleDuaDateChange('originalExecutionDate', event)
                                         }}
                                         value={duaDetails?.originalExecutionDate}/>
                </div>

                <div style={executionTabStyle}>
                    <div style={{width: "40%"}}>Current Expiration:</div>
                    <DatePickerComponent style={{width: "50%"}}
                                         readonly={isOrgAdmin}
                                         onChange={(event) => {
                                             handleDuaDateChange('currentExpirationDate', event)
                                         }}
                                         value={duaDetails?.currentExpirationDate}/></div>
            </div>

            <div style={{display: "flex", gap: "50px"}}>
                <div style={{width: "50%"}}>
                    <TextAreaComponent name={'duaStatus'}
                                       label={'DUA Status'}
                                       value={statusUpdateState?.duaStatus}
                                       onChange={(event) => {
                                           handleDuaStatusChange(event.target.value)
                                       }}
                                       readonly={isOrgAdmin}
                                       style={inputStyle}/>
                </div>

                {isVivliAdmin &&
                    <div style={{width: "50%"}}>
                        <TextAreaComponent label="DUA Internal notes"
                                           value={duaDetails?.internalNotes}
                                           onChange={(event) => {
                                               handleDuaUpdate('internalNotes', event.target.value)
                                           }}
                                           style={{backgroundColor: 'rgb(255, 246, 217)'}}/>
                    </div>
                }

            </div>


            {/*WIll be implemented in the next story(10905) */}


            {/*<div style={{marginTop: "20px"}}>*/}
            {/*    <h3>Individual DUA documents</h3>*/}
            {/*    <IndividualDuaDocumentsTableComponent/>*/}
            {/*</div>*/}

            {/*<div style={{display: "flex", justifyContent: "center", alignItems: "center"}}>*/}
            {/*    <div style={{width: "50%"}}>*/}
            {/*        <h3>Enter Institition</h3>*/}

            {/*        <div style={institutionContainerStyle}>*/}
            {/*            <div style={institutionDivStyle}>*/}
            {/*                Search ROR for name of institution or enter it directly*/}
            {/*                <div style={{display: "flex", justifyContent: "space-between"}}>*/}
            {/*                    <div style={{width: "60%"}}>*/}
            {/*                        <TextFieldComponent innerStyle={{width: "94%"}}/>*/}
            {/*                    </div>*/}
            {/*                    <ButtonComponent>Search ROR</ButtonComponent>*/}
            {/*                </div>*/}
            {/*            </div>*/}

            {/*            <div style={institutionDivStyle}>*/}
            {/*                The following institution name will be used*/}
            {/*                <div style={{display: "flex", justifyContent: "space-between"}}>*/}
            {/*                    <div style={{width: "60%"}}>*/}
            {/*                        <TextFieldComponent innerStyle={{width: "94%"}}/>*/}
            {/*                    </div>*/}
            {/*                    <ButtonComponent>Save Name</ButtonComponent>*/}
            {/*                </div>*/}
            {/*            </div>*/}

            {/*        </div>*/}

            {/*    </div>*/}
            {/*</div>*/}
        </div>
    )
}
